/* eslint-disable camelcase */
/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../../components/app-context";
import { xlate } from "../../../commonUtil/i18n/locales";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SearchableSelect from "@cx/ui/SearchableSelect";
import CheckBoxList from "@cx/ui/CheckBoxList";
import Button from "@cx/ui/Button";
import { toast } from "@cx/ui/Toast";
import {
  CopyGlobalRepairTemplate,
  CopyAnyMakeDealerTemplate,
  CopyDealerTemplate,
  GlobalOperationsChkValues,
  SettingsChkValues,
  OperationsChkValues,
  MenusChkValues,
  PartsLaborChkValues
} from "../../../constants/ModuleConstants";
import { makeSecureRestApi } from "../../../api/xmmAxios";
import Confirmation from "../../../commonUtil/dialog/Confirmation";
import PickyFilter from "../../../commonUtil/filters/PickyFilter";
import { hasUserRole } from "../../../role/roleUtil";
import { launchCatalogManager } from "../../reusable/launch";
import { toEmptyStringIfUndefined } from "../../../commonUtil/utils/string";

class DealerAdmin extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.onSelectVariant = this.onSelectVariant.bind(this);
    this.onSelectDealer = this.onSelectDealer.bind(this);
    this.onSelectTargetDealer = this.onSelectTargetDealer.bind(this);
    this.copyDealerHandler = this.copyDealerHandler.bind(this);
    this.onChangeSelectAll = this.onChangeSelectAll.bind(this);
    this.onChangeSettings = this.onChangeSettings.bind(this);
    this.onChangeMenus = this.onChangeMenus.bind(this);
    this.onSelectMenu = this.onSelectMenu.bind(this);
    this.handleCopyAction = this.handleCopyAction.bind(this);
    this.closeCopyModal = this.closeCopyModal.bind(this);
    this.initializeLocaleValues();
    const { role } = context;
    const hasRole = hasUserRole("dealerAdmin", role);
    this.state = {
      hasRole,
      formParams: {
        fromDealerName: "",
        toDealerName: ""
      },
      dealer: null,
      targetDealer: null,
      search: {
        make: "",
        variant: "",
        dealerCode: "",
        targetDealerCode: ""
      },
      formReady: false,
      menuTypes: [],
      selectedMenuTypeIds: [],
      disableMenu: true,
      // disableCopy: true,
      errors: {},
      selectedSettings: [],
      selectedOperations: [],
      selectedMenus: [],
      selectedPartsLabor: [],
      variants: [],
      dealerCodes: [],
      targetDealerCodes: [],
      checkAll: false,
      copyConfirmMessage: "",
      isReady: true
    };
  }
  componentDidMount() {
    this.loadVariants();
  }

  initializeLocaleValues() {
    this.pageTitle = xlate("xmmadmin.portal.nav.copytodealerdealer_plmp");
  }
  onSelectVariant = (cxEvent, isValid, domEvent) => {
    const { checkAll, search, formParams } = this.state;
    const value = cxEvent.target.value;
    const optionValue = value && value.length !== 0 ? value[0].value : "";
    if (optionValue !== "") {
      const makeVal = optionValue.split("/");
      search.make = makeVal[0].trim();
      search.variant = makeVal[1].trim();
      search.dealerCode = "";
      search.targetDealerCode = "";
      search.globalRepairsSelected = search.variant === GlobalRepairs;
      formParams.fromDealerName = "";
      formParams.toDealerName = "";
      if (checkAll) {
        this.onChangeSelectAll({ target: { name: "checkAll", checked: true } });
      }
      this.setState(
        {
          search,
          formParams,
          formReady: false,
          dealerCodes: [],
          targetDealerCodes: [],
          menuTypes: [],
          selectedMenus: [],
          selectedMenuTypes: [],
          selectedMenuTypeIds: []
        },
        () => {
          console.log("variant select", optionValue, search);
          if (search.globalRepairsSelected) {
            this.loadGlobalRepairDealers();
          } else {
            this.loadDealers();
          }
        }
      );
    } else {
      search.make = "";
      search.variant = "";
      search.dealerCode = "";
      search.targetDealerCode = "";
      formParams.fromDealerName = "";
      formParams.toDealerName = "";
      this.setState({
        search,
        formParams,
        formReady: false,
        dealerCodes: [],
        targetDealerCodes: [],
        menuTypes: [],
        selectedMenus: [],
        selectedMenuTypes: [],
        selectedMenuTypeIds: []
      });
    }
  };

  onSelectDealer = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const { search, formParams } = this.state;
    const { dealerCode } = search;
    const dealer = Array.isArray(value) && value.length !== 0 ? value[0] : null;
    const optionValue = dealer ? dealer.value : "";
    if (optionValue !== dealerCode) {
      search.dealerCode = optionValue;
      search.targetDealerCode = "";
      const dealerName =
        Array.isArray(value) && value.length !== 0 ? value[0].dealerName : "";
      formParams.fromDealerName = dealerName;
      formParams.toDealerName = "";
      this.setState(
        {
          dealer,
          search,
          formParams,
          targetDealerCodes: []
        },
        () => {
          if (optionValue !== "") {
            this.fetchTargetDealers();
            this.loadMenuTypes();
          }
        }
      );
    }
  };
  onSelectTargetDealer = (cxEvent, isValid, domEvent) => {
    const { value } = cxEvent.target;
    const { search, formParams } = this.state;
    const { targetDealerCode } = search;
    const targetDealer =
      Array.isArray(value) && value.length !== 0 ? value[0] : null;
    const optionValue = targetDealer ? targetDealer.value : "";
    if (optionValue !== targetDealerCode) {
      search.targetDealerCode = optionValue;
      const dealerName =
        Array.isArray(value) && value.length !== 0 ? value[0].dealerName : "";
      formParams.toDealerName = dealerName;
      this.setState(
        {
          targetDealer,
          search,
          formParams,
          formReady: true
        },
        () => {
          // callback here
        }
      );
    }
  };
  onChangeSettings = (cxEvent, cxValid) => {
    const { name, value } = cxEvent.target;
    // let isValid = true;
    const { errors } = Object.assign({}, this.state);
    if (value && value.length > 0) {
      // isValid = true;
    } else if (value === null || value.length === 0) {
      // isValid = false;
    }
    // errors[name] = isValid ? undefined : "Field is Required";
    // console.log("chk  values", value);
    this.setState(prevState => ({
      [name]: value,
      // disableCopy: !isValid,
      errors
    }));
  };

  onChangeMenus = (cxEvent, cxValid) => {
    const { name, value } = cxEvent.target;
    let { selectedMenuTypeIds } = this.state;
    let isValid = true;
    const { errors } = Object.assign({}, this.state);
    if (value && value.length > 0) {
      isValid = true;
    } else if (value === null || value.length === 0) {
      isValid = false;
      selectedMenuTypeIds = [];
    }
    // errors[name] = isValid ? undefined : "Field is Required";
    // console.log("chk menus values", value);
    this.setState(prevState => ({
      [name]: value,
      selectedMenuTypeIds,
      disableMenu: !isValid,
      // disableCopy: !isValid,
      errors
    }));
  };

  /* "checkAll" checkbox change handler */
  onChangeSelectAll = event => {
    const { search } = this.state;
    const { globalRepairsSelected } = search;
    if (globalRepairsSelected) {
      this.onChangeSelectAllGlobal(event);
    } else {
      this.onChangeSelectAllDealer(event);
    }
  };

  onChangeSelectAllDealer = event => {
    const { search } = this.state;
    const target = event.target;
    const { name } = target;
    // const disableCopy = !target.checked;
    const selectedSettings = [
      "copyDealerSettings",
      "copyIntervals",
      "copyVehicleGroups"
    ];
    const selectedOperations = [
      "copyDealerServices",
      "copyServicePrices",
      "copyServiceOpcodes",
      "copyServiceOther",
      "copyLaborOverrides"
    ];
    const selectedMenus = ["copyRecommendations", "copyPackages"];
    const selectedPartsLabor = ["copyParts", "copyFluids", "copyLaborRates"];
    const selectedMenuTypes = this.state.menuTypes;

    if (search.make === "ANY") {
      selectedSettings.length = 1;
      selectedOperations.length = 4;
      selectedMenus.length = 0;
      selectedPartsLabor.length = 0;
      selectedMenuTypes.length = 0;
    }

    this.setState({
      [name]: target.checked,
      selectedSettings: target.checked ? selectedSettings : [],
      selectedOperations: target.checked ? selectedOperations : [],
      selectedMenuTypes: target.checked ? selectedMenuTypes : [],
      selectedMenus: target.checked ? selectedMenus : [],
      selectedMenuTypeIds: target.checked
        ? selectedMenuTypes.map(m => m.menuTypeId.toString())
        : [],
      selectedPartsLabor: target.checked ? selectedPartsLabor : [],
      disableMenu: !target.checked
    });
  };

  onChangeSelectAllGlobal = event => {
    const target = event.target;
    const { name } = target;
    const selectedOperations = [
      "copyRepairOpSettings",
      "copyRepairOpPriceOverrides"
    ];

    this.setState({
      [name]: target.checked,
      selectedSettings: [],
      selectedOperations: target.checked ? selectedOperations : [],
      selectedMenuTypes: [],
      selectedMenus: [],
      selectedMenuTypeIds: [],
      selectedPartsLabor: [],
      disableMenu: !target.checked
    });
  };

  onSelectMenu = selectedMenuTypes => {
    console.log("menu selected", selectedMenuTypes);
    const selectedMenuTypeIds = selectedMenuTypes.map(m =>
      m.menuTypeId.toString()
    );
    this.setState({ selectedMenuTypes, selectedMenuTypeIds });
    console.log("selected menuType IDs", selectedMenuTypeIds);
  };

  // add filter to remove TO dealercode from list
  fetchTargetDealers() {
    const { rawDealerCodes, search } = this.state;
    const { dealerCode } = search;

    if (rawDealerCodes && rawDealerCodes.length > 0) {
      const filterList = rawDealerCodes.filter(function (obj) {
        return obj.dealerCode !== dealerCode;
      });
      this.setState({
        targetDealerCodes: filterList
      });
    }
  }

  loadMenuTypes() {
    const { search } = this.state;
    const restUrl =
      "/opsadmin/proxyapi/ddsproxy/rest/proc/getEnhancedMenuTypes";
    const params = {
      dealerCode: search.dealerCode,
      make: search.make,
      variant: search.variant,
      locale: "en_US"
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params,
        headers
      },
      response => {
        const menuTypes = [];
        if (response) {
          const datalist = response;
          if (datalist && datalist.length > 0) {
            datalist.forEach(m => {
              if (!m.menuTypeId || !m.name) {
                // Don't add menu
              } else {
                m.label = !m.name ? "" : m.name.toString();
                m.value = m.menuTypeId.toString();
                menuTypes.push(m);
              }
            });
          }
          this.setState({
            menuTypes
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  loadVariants() {
    const restUrl =
      "/opsadmin/proxyapi/ddsproxy/rest/table/getEnhancedAndAnyVariants";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        headers
      },
      response => {
        const variants = [{ label: AnyGlobalRepairs, value: AnyGlobalRepairs }];
        if (response) {
          const datalist = response;
          if (datalist && datalist.length > 0) {
            datalist.forEach(m => {
              if (m.enhancedPricing === 1 || m.make === "ANY") {
                const newValue = m.make + " / " + m.variant;
                m.label = newValue;
                m.value = newValue;
                variants.push(m);
              }
            });
          }
          variants.sort((d1, d2) => {
            const a = d1.label.toLowerCase();
            const b = d2.label.toLowerCase();
            return a === b ? 0 : a > b ? 1 : -1;
          });
          // console.log("Variants", variants);
        }
        this.setState({ variants });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  loadDealers() {
    const { search } = this.state;
    const restUrl =
      "/opsadmin/proxyapi/ddsproxy/rest/table/getEnhancedAndAnyVariantDealers";
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: {
          make: search.make,
          variant: search.variant
        },
        headers
      },
      response => {
        const dealerCodes = [];
        if (response) {
          const datalist = response;
          if (datalist && datalist.length > 0) {
            datalist.forEach(m => {
              if (m.dealerCode) {
                const newValue = m.dealerName + " (" + m.dealerCode + ")";
                m.label = newValue;
                m.value = m.dealerCode;
                dealerCodes.push(m);
              }
            });
            dealerCodes.sort((d1, d2) => {
              const a = d1.dealerName.toLowerCase();
              const b = d2.dealerName.toLowerCase();
              return a === b ? 0 : a > b ? 1 : -1;
            });
          }
          // console.log("Dealers", dealerCodes);
        }
        const rawDealerCodes = [...dealerCodes];
        this.setState({
          dealerCodes,
          rawDealerCodes
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  loadGlobalRepairDealers() {
    const propertyName = "COMMON_OPS_REPAIRS_ENABLED";
    const propertyValue = "Y";
    const params = { propertyName, propertyValue };
    const restUrl =
      "/opsadmin/proxyapi/ddsproxy/rest/proc/listDealersByProperty";
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        params
      },
      response => {
        const dealerCodes = [];
        if (response) {
          const datalist = response;
          if (datalist && datalist.length > 0) {
            datalist.forEach(m => {
              if (m.dealerCode) {
                const newValue = m.dealerName + " (" + m.dealerCode + ")";
                m.make = "ANY";
                m.label = newValue;
                m.value = m.dealerCode;
                dealerCodes.push(m);
              }
            });
            dealerCodes.sort((d1, d2) => {
              const a = d1.dealerName.toLowerCase();
              const b = d2.dealerName.toLowerCase();
              return a === b ? 0 : a > b ? 1 : -1;
            });
          }
          // console.log("Dealers", dealerCodes);
        }
        const rawDealerCodes = [...dealerCodes];
        this.setState({
          dealerCodes,
          rawDealerCodes
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  handleCopyAction = event => {
    if (event) {
      event.preventDefault();
    }
    const { toDealerName, fromDealerName } = this.state.formParams;
    const msg = `Do you want to copy this data from ${fromDealerName} to ${toDealerName}? This copy may overwrite existing data or create duplicate data and cannot be reversed!`;
    this.setState({
      showCopyModal: true,
      copyConfirmMessage: msg
    });
  };

  closeCopyModal = event => {
    if (event) {
      event.preventDefault();
    }
    this.setState({
      showCopyModal: false,
      copyConfirmMessage: ""
    });
  };

  copyDealerHandler = event => {
    if (event) {
      event.preventDefault();
    }
    let isValid = true;
    const { errors } = this.state;
    // console.log("form errors", errors);
    // check if any errors exist on fields
    if (Object.keys(errors).length) {
      isValid = false;
    }
    const { search } = this.state;
    const { make, globalRepairsSelected } = search;
    const payload = globalRepairsSelected
      ? this.getCopyGlobalPayload()
      : make === "ANY"
      ? this.getCopyAnyMakePayload()
      : this.getCopyPayload();
    this.setState({
      showCopyModal: false
    });
    // console.log("payload to save", payload);
    if (payload && Object.keys(payload).length === 0) {
      return;
    }
    // cancel save if we have errors on fields
    if (!isValid) {
      toast.warning("Please correct errors");
      return;
    }
    const restAPI = globalRepairsSelected
      ? "/opsadmin/operations/copyOperationOverrides"
      : make === "ANY"
      ? "/opsadmin/proxyapi/ddsproxy/rest/proc/copyDealerAnyMake"
      : "/opsadmin/proxyapi/ddsproxy/rest/proc/copyEnhancedDealer2";

    makeSecureRestApi(
      {
        url: restAPI,
        method: "post",
        data: payload
      },
      response => {
        if (!globalRepairsSelected) {
          this.onSucessCallback(response[0]);
        } else {
          const { copy_operation_overrides } = response;
          if (copy_operation_overrides === 0) {
            toast.success("Copy successful");
          } else {
            toast.success("Error occurred while copying");
          }
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "Error occurred while copying";
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  onSucessCallback = record => {
    const { isError, message } = record;
    if (isError === 0) {
      toast.success(message);
    } else {
      toast.error(message);
    }
  };
  getCopyPayload() {
    const {
      search,
      selectedSettings,
      selectedOperations,
      selectedPartsLabor,
      selectedMenus,
      selectedMenuTypeIds,
      disableMenu
    } = this.state;
    const payload = Object.assign({}, CopyDealerTemplate);
    payload.make = search.make;
    payload.variant = search.variant;
    payload.dealerCode = search.dealerCode;
    payload.targetDealerCode = search.targetDealerCode;

    if (selectedSettings.length > 0) {
      // copy array elements to th object
      selectedSettings.forEach(ck => (payload[ck] = 1));
    }
    if (selectedOperations.length > 0) {
      selectedOperations.forEach(ck => (payload[ck] = 1));
      // for (let i = 0; i < selectedOperations.length; i++) {
      //   payload[selectedOperations[i]] = 1;
      // }
    }
    if (selectedMenus.length > 0) {
      selectedMenus.forEach(ck => (payload[ck] = 1));
    }
    if (selectedPartsLabor.length > 0) {
      selectedPartsLabor.forEach(ck => (payload[ck] = 1));
    }

    if (!disableMenu) {
      payload["menuTypes"] = selectedMenuTypeIds;
    }
    return payload;
  }
  getCopyGlobalPayload() {
    const { userName } = this.context;
    const { search, selectedOperations } = this.state;
    const payload = Object.assign({}, CopyGlobalRepairTemplate);
    payload.dealerCode = search.dealerCode;
    payload.targetDealerCode = search.targetDealerCode;
    payload.modUser = userName;

    if (selectedOperations.length > 0) {
      selectedOperations.forEach(ck => (payload[ck] = 1));
    }
    return payload;
  }
  getCopyAnyMakePayload() {
    const { search, selectedSettings, selectedOperations } = this.state;
    const payload = Object.assign({}, CopyAnyMakeDealerTemplate);
    payload.make = search.make;
    payload.variant = search.variant;
    payload.dealerCode = search.dealerCode;
    payload.targetDealerCode = search.targetDealerCode;

    if (selectedSettings.length > 0) {
      // copy array elements to th object
      selectedSettings.forEach(ck => (payload[ck] = 1));
    }
    if (selectedOperations.length > 0) {
      selectedOperations.forEach(ck => (payload[ck] = 1));
    }
    return payload;
  }
  render() {
    const {
      hasRole,
      isReady,
      checkAll,
      errors,
      variants,
      dealerCodes,
      targetDealerCodes,
      disableMenu,
      selectedSettings,
      selectedOperations,
      selectedPartsLabor,
      selectedMenus,
      selectedMenuTypeIds,
      formReady,
      search
    } = this.state;
    const { dealerCode, targetDealerCode, globalRepairsSelected } =
      this.state.search;
    const maskStyle = formReady
      ? "xmm-border-line"
      : "xmm-border-line xmm-mask";

    let markDisabled = true;
    const toggleCount =
      selectedSettings.length +
      selectedOperations.length +
      selectedPartsLabor.length +
      selectedMenus.length;
    markDisabled =
      toEmptyStringIfUndefined(dealerCode) !== "" &&
      toEmptyStringIfUndefined(targetDealerCode) !== "" &&
      toggleCount !== 0 &&
      (selectedMenus.length === 0 || selectedMenuTypeIds.length !== 0)
        ? false
        : true;

    const copyConfirmationDialog = (
      <Confirmation
        htmlId="copyDealerDialog"
        message={this.state.copyConfirmMessage}
        proceedButtonStyle="danger"
        show={this.state.showCopyModal}
        actionFunction={this.copyDealerHandler}
        closeDialog={this.closeCopyModal}
      />
    );
    let enhancedClass = "";
    let settingClass = "";
    const settingsChkValues = [...SettingsChkValues];
    const operationsChkValues = globalRepairsSelected
      ? [...GlobalOperationsChkValues]
      : [...OperationsChkValues];
    if (globalRepairsSelected) {
      enhancedClass = "hide";
      settingClass = "hide";
      settingsChkValues.length = 0;
    } else {
      if (search.make === "ANY") {
        enhancedClass = "hide";
        settingsChkValues.length = 1;
        operationsChkValues.length = 4;
      }
    }

    const disabled = !hasRole;
    return (
      <div className="xmm-center-container" disabled={disabled}>
        <h3>{this.pageTitle}</h3>
        <Grid className="">
          <Row>
            <Col xs={12} md={12} className="full-col">
              <div className="xmm-push-search-container">
                <Col md={5} sm={5} className="">
                  <div className="xmm-dealer-select">
                    <SearchableSelect
                      htmlId="variant"
                      className="dealer-div"
                      name="variant"
                      label="Catalog"
                      placeholder={"Select"}
                      disabled={variants.length === 0}
                      enableMultiSelect={false}
                      maxHeight={400}
                      maxResults={1000}
                      paginate={true}
                      onPaginate={e => console.log("Results paginated")}
                      value=""
                      options={variants}
                      onChange={this.onSelectVariant}
                    />
                    <div className="launch-icon" />
                  </div>
                  <div className="xmm-dealer-select">
                    <SearchableSelect
                      htmlId="dealerCode"
                      className="dealer-div"
                      name="dealerCode"
                      label={"Copy From"}
                      placeholder={"Select"}
                      disabled={dealerCodes.length === 0}
                      enableMultiSelect={false}
                      maxHeight={400}
                      maxResults={1000}
                      paginate={true}
                      onPaginate={e => console.log("Results paginated")}
                      value=""
                      options={dealerCodes}
                      onChange={this.onSelectDealer}
                    />
                    <div
                      className="launch-icon"
                      disabled={dealerCode === ""}
                      onClick={() => {
                        const { dealer } = this.state;
                        launchCatalogManager(dealer.webKey);
                      }}
                    >
                      <img
                        src={require("../../../images/launch_icon.svg")}
                        alt="VinSolutions logo"
                      />
                    </div>
                  </div>
                  <div className="xmm-dealer-select">
                    <SearchableSelect
                      htmlId="targetDealerCode"
                      className="dealer-div"
                      name="targetDealerCode"
                      label={"To"}
                      placeholder={"Select"}
                      disabled={targetDealerCodes.length === 0}
                      enableMultiSelect={false}
                      maxHeight={400}
                      maxResults={1000}
                      paginate={true}
                      onPaginate={e => console.log("Results paginated")}
                      value=""
                      options={targetDealerCodes}
                      onChange={this.onSelectTargetDealer}
                    />
                    <div
                      className="launch-icon"
                      disabled={targetDealerCode === ""}
                      onClick={() => {
                        const { targetDealer } = this.state;
                        launchCatalogManager(targetDealer.webKey);
                      }}
                    >
                      <img
                        src={require("../../../images/launch_icon.svg")}
                        alt="VinSolutions logo"
                      />
                    </div>
                  </div>
                </Col>
              </div>
              <Col md={1} sm={1}>
                <div>&nbsp;</div>
              </Col>
              <Col md={6} sm={6} className={maskStyle}>
                <div className="xmm-fixed-col">
                  <div className="xmm-checkbox-container xmm-horizontal-form">
                    <input
                      className="form-checkbox"
                      name="checkAll"
                      id="checkAll"
                      type="checkbox"
                      disabled={!isReady ? true : false}
                      checked={checkAll || false}
                      onChange={this.onChangeSelectAll}
                    />
                    <span className="xmm-checkmark" />
                    <span className="label-checkbox">Check All</span>
                  </div>
                  <p>&nbsp;</p>
                  <CheckBoxList
                    htmlId="chkSettings"
                    name="selectedSettings"
                    label="Settings"
                    className={settingClass}
                    onChange={this.onChangeSettings}
                    values={this.state.selectedSettings}
                    options={settingsChkValues}
                    error={errors.selectedSettings}
                  />
                  <div className={settingClass}>&nbsp;</div>
                  <CheckBoxList
                    htmlId="chkOperations"
                    name="selectedOperations"
                    label="Operations"
                    onChange={this.onChangeSettings}
                    values={this.state.selectedOperations}
                    options={operationsChkValues}
                    error={errors.selectedOperations}
                  />
                  <div>&nbsp;</div>
                  {/* <div className="xmm-input-col" id={"settings-menus"}>
                    <SearchableSelect
                      htmlId="menuTypes"
                      name="menuTypes"
                      label={"Menus"}
                      placeholder={"Select"}
                      disabled={disableMenu}
                      maxHeight={200}
                      value={this.state.selectedMenuTypeIds}
                      options={this.state.menuTypes}
                      onChange={this.onSelectMenu}
                    />
                  </div> */}
                  <div className={enhancedClass}>
                    <div className="xmm-picky-menu-types" id={"settings-menus"}>
                      <PickyFilter
                        htmlId="menuTypesPicky"
                        name="menuTypes"
                        disabled={disableMenu}
                        placeholder={"Select"}
                        label={"Menus"}
                        initialValue={this.state.selectedMenuTypes}
                        options={this.state.menuTypes}
                        includeFilter={false}
                        includeSelectAll={true}
                        numberDisplayed={1}
                        dropdownHeight={200}
                        onChange={this.onSelectMenu}
                      />
                    </div>

                    <CheckBoxList
                      htmlId="chkMenus"
                      name="selectedMenus"
                      label=""
                      displayLabel={false}
                      onChange={this.onChangeMenus}
                      values={this.state.selectedMenus}
                      options={MenusChkValues}
                      error={errors.selectedMenus}
                    />
                    <div>&nbsp;</div>
                    <CheckBoxList
                      htmlId="chkPartsLabor"
                      name="selectedPartsLabor"
                      label={"Parts & Labor"}
                      onChange={this.onChangeSettings}
                      values={this.state.selectedPartsLabor}
                      options={PartsLaborChkValues}
                      error={errors.selectedPartsLabor}
                    />
                  </div>
                </div>
                <Button
                  htmlId="btnCopyDealer"
                  disabled={markDisabled}
                  onClick={this.handleCopyAction}
                >
                  Copy to Dealer
                </Button>
              </Col>
            </Col>
            {copyConfirmationDialog}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default DealerAdmin;

const AnyGlobalRepairs = "ANY / GLOBAL REPAIRS";
const GlobalRepairs = "GLOBAL REPAIRS";
